$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

.headerbackcolor{
  background-color: var(--color-primary);
}
.header {
  font-size: 1.4rem;
  height: 7rem;
  background-color: var(--color-primary);
  // border-bottom: var(--line);
  position: sticky;
  top: 0;
  // overflow: hidden;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  // @media only screen and (max-width: $bp-smallest) {
  //   flex-wrap: wrap;
  //   align-content: space-around;
  //   height: 11rem;
  // }

  .loginButton {
    background: var(--color-primary-light);
    border: none;
    box-shadow: var(--shadow-dark);
    color: #fff;
    padding: 0.5rem 3rem;
    font-size: 1.5rem;
    margin-right: 4rem;
    cursor: pointer;
  }
}

.content {
  display: flex;

  @media only screen and (max-width: $bp-medium) {
    flex-direction: column;
  }
}

////////////////////////////////////////
// LOGO
.logo {
  height: 4.25rem;
  margin-left: 2rem;
  cursor: pointer;
  // @media screen and (max-width: 500px) {
  //   height: 3.3rem;
  //   margin-left: 0.5rem;
  // }
  // @media screen and (max-width: 375px) {
  //   height: 2.5rem;
  //   margin-left: 0;
  // }
}

////////////////////////////////////////
// SEARCH
.search {
  //flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-self: flex-start;

  @media only screen and (max-width: $bp-smallest) {
    order: 1;
    flex: 0 0 100%;
    background-color: var(--color-grey-light-2);
  }

  &__input {
    font-family: inherit;
    font-size: 1.8rem;
    color: inherit;
    background-color: var(--color-grey-light-2);
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 1rem;
    //width: 60%;
    transition: all 0.2s;
    margin-right: -3.25rem;
    //margin-left: 8rem;

    &:focus {
      outline: none;
      // width: 100%;
      background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 300;
      font-size: 1.6rem;
      color: #000;
    }
  }

  &__inputMobile {
    font-family: inherit;
    //width: 72.2%;
    font-size: inherit;
    color: inherit;
    background-color: var(--color-grey-light-2);
    border: none;
    padding: 0.7rem 1rem;
    border-radius: 1rem;
    transition: all 0.2s;
    //margin-right: -3.25rem;
    //margin-left: 8rem;
    @media screen and (max-width: 426px) {
      width: 75%;
    }

    &:focus {
      outline: none;
      // width: 100%;
      background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 300;
      font-size: 1.6rem;
      color: rgb(129, 129, 129);
    }
  }

  &__input:focus + &__button {
    background-color: var(--color-grey-light-3);
  }

  &__button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__input:focus + &__buttonMobile {
    background-color: var(--color-grey-light-3);
  }

  &__buttonMobile {
    border: none;
    cursor: pointer;
    background-color: #217fb4;
    color: #fff;
    font-size: 1.4rem;
    margin: 0px 0px 0px 8px ;
    padding: 5px 10px;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: var(--color-grey-dark-3);
  }
}

.hide {
  display: none;
}

.bt {
  margin: 0 0 0 22px!important;
  @media screen and (max-width: 510px) {
    margin: 0 0 0 8px !important;
  }
}

////////////////////////////////////////
// USER NAVIGATION
.user-nav {
  align-self: stretch;
  display: flex;
  align-items: center;

  & > * {
    padding: 0 2rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    & > button {
      padding: 0;
    }
    @media screen and (max-width: 675px) {
      padding: 0rem 0.2rem;
      width: 2.9rem;
    }
  }

  // & > *:hover {
  //   background-color: var(--color-grey-light-2);
  // }

  &__icon-box {
    position: relative;
  }

  &__icon {
    height: 2.25rem;
    width: 2.25rem;
    fill: var(--color-grey-dark-2);
  }

  &__notification {
    font-size: 0.8rem;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    background-color: var(--color-grey-light-2);
    color: var(--color-primary);
    position: absolute;
    top: 1.5rem;
    right: 1.1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__user-photo {
    height: 3.75rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  &__user-name {
    font-size: 1.45rem;
    color: var(--color-white);
    text-transform: none;
    font-weight: 400;
  }
}
.user-nav__user {
  padding: 0 !important;
  position: relative !important;
}
.rightdropdown {
  line-height: 54px !important;
  width: 135px !important;
}
.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.confirmButton {
  background-color: var(--color-primary) !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1.75rem !important;
  padding: 1rem 2rem !important;
  color: #fff;
  border-radius: 0.4rem;
}

.swalTitle {
  font-size: 1.75rem;
  color: #000;
  font-weight: 600;
}

[data-tippy-root] {
  @media screen and (max-width: 426px) {
    //transform: translate3d(5px, 75px, 0px)!important;
    inset: 0px auto auto 0px;
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    z-index: 100!important;
  }
}