@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
  font-family: myfontregular;
}

.loignformdiv2 p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.loignformdiv2 span {
  font-size: 12px;
  color: red;
}

.loignformdiv2 button {
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  background: var(--linear-gradient);
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.signup {
  border: 1px solid #5c5c5c !important;
  background: #fff !important;
  color: #5c5c5c !important;
  margin-left: 10px;
}

.loignformdiv2 button:active {
  transform: scale(0.95);
}

.loignformdiv2 button:focus {
  outline: none;
}

.loignformdiv2 button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.loignformdiv2 input {
  background-color: transparent;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size: 14px;
}
.loginform input {
  background-color: transparent;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.signincontainer {
  left: 0;
  width: 50%;
  z-index: 2;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.loginheader {
  font-size: 2.6rem !important;
  margin: 0px 0 30px 0px;
  font-family: myfontregular;
  color: #5c5c5c;
  text-align: center;
}
.formcontainer {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}
.loignformdiv2 .container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}
.loginformdiv {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/media/homepage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.loignformdiv2 {
  width: 600px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--shadow-drak);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loignformdiv3 {
  width: 600px;
  max-height: 600px;
  padding: 3rem 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--shadow-drak);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 601px) {
    width: 500px;
    & > form > div {
      width: inherit!important;
      & > button {
        margin: 0em 0.5em;
      }
    }
  }
  @media screen and (max-width: 502px) {
    width: auto;
  }
}

.swal2-popup {
  font-size: 1.5rem !important;
}