.overall-container {
  width: 795px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 30px 0; */
  overflow-y: hidden;
}

.certcontainer {
  width: 100%;
  height: 71vh;
  text-align: center;
  display: flex;
  padding: 2%;
 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: max-content;
  overflow-y: hidden;
  background-color: #ffffff;
}
.container .img-c {
  display: flex;
  justify-content: center;
}
.container .org-container {
  margin: 25px 0;
  line-height: 1;
}

.container .name {
  margin: 0 0 15px 0;
}
.container .complete {
  margin: 0 0 15px 0;
}
.title-container {
  margin: 0 0 15px 0;
  line-height: 1;
}
.container .org-container .org {
  color: #ff6f4d;
  font-size: 35px;
  font-weight: 900;
}
.date {
  margin: 13px 0;
}

.clearfix {
  max-width: 750px;
}

.title-container .truncate {
  display: -webkit-box;
  max-width: 700px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.container-1 {
  width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #777777;
  padding: 5% 0;
  max-height: 300px;
  cursor: pointer;
  background-color: #ffffff;
  @media screen and (max-width: 430px) {
    width: -webkit-fill-available;
    margin: 0rem 3rem;
  }
  @media screen and (max-width: 375px) {
    margin: 0rem 1rem;
  }
}
.container-1 .img-c {
  display: flex;
  justify-content: center;
}
.container-1 .org-container-1 {
  margin: 12px 0;
  line-height: 1;
}
.org-container-1 .org {
  font-size: 15px;
  font-weight: 900;
}
.container-1 .name {
  margin: 0 0 5px 0;
}
.container-1 .complete {
  margin: 0 0 5px 0;
  font-size: 9px;
}
.title-container-1 {
  margin: 0 0 5px 0;
  line-height: 1;
}
.container-1 .org-container-1 .org {
  color: #ff6f4d;
}
.container-1 .org-container-1 p {
  font-size: 9px;
}
.date-1 {
  margin: 7px 0;
  font-size: 9px;
}

.title-1 {
  font-size: 12px;
}
.name-1 {
  font-size: 12px;
}

.course-certificatepopup-main-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
.course-certificatepopup {
  background-image: url("../../assets/certificateTemplate.png");
  background-size: cover;
  background-position: center;
  // background-repeat: no-repeat;
  object-fit: cover;
  width: 780px;
  height: 67vh;
  position: relative;
  padding: 1rem;
  &_container {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -45%);
    max-width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    position: relative;
    &_name {
      font-family: 'myfontbold';
      font-size: 4.5rem;
      color: #1B1464;
      font-weight: 900;
      text-transform: capitalize;
      margin: 0;
      padding: 0;
      text-align: center;
      width:100%;
      overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // background:#fff;
      position:absolute;
    }
  }
  &_details {
    position: relative;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -45%);
    max-width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    
    // align-items: ;
    font-family: 'myfontregular';
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    letter-spacing: 1;

    &_top {

      &-head {
        text-align: center;
       
      }
      &-title {
        text-align: center;
        font-weight: normal !important;
        
      }
    }

  }

}
.certificatepopup_actions{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  &-btn {
    padding: 1rem 2rem;
    background-color: var(--color-primary);
    color: white;
  }
}
}