.explore_position {
  height: 250px;
  padding: 1.5rem 0rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: 600;
    font-size: 2rem;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
.explore_position_course {
  height: 350px;
  padding: 1.5rem 0rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: 600;
    font-size: 2rem;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.result_not_found {
  width: 100%;
  height: calc(100vh - 250px);
  display: grid;
  place-items: center;
  position: relative;

  .text {
    font-weight: 600;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
