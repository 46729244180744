// .dialog-container{
//     width: 90vw;
//     height: 80vh;
// }

.course-certificate {
    background-image: url("../../assets/certificateTemplate.png");
    background-size: cover;
    background-position: center;
    // background-repeat: no-repeat;
    object-fit: cover;
    width: 850px;
    height: 85vh;
    position: relative;
    padding: 1rem;
    &_container {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -45%);
      max-width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: end;
      flex-direction: column;
      position: relative;
      &_name {
        font-family: 'myfontbold';
        font-size: 4.5rem;
        color: #1B1464;
        font-weight: 900;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
        text-align: center;
        width:100%;
        overflow: hidden;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // background:#fff;
        position:absolute;
      }
    }
    &_details {
      position: relative;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -45%);
      max-width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      
      // align-items: ;
      font-family: 'myfontregular';
      font-weight: 900;
      font-size: 24px;
      text-align: center;
      letter-spacing: 1;

      &_top {

        &-head {
          text-align: center;
         
        }
        &-title {
          text-align: center;
          font-weight: normal !important;
          
        }
      }

    }
  
  }
  .certificate_actions{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    &-btn {
      padding: 1rem 2rem;
      background-color: var(--color-primary);
      color: white;
    }
  }