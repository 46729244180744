.PhoneInput {
  height: 40px;
  border-bottom: 0.2em solid #3f51b5;
  font-size: 1rem;
  padding: 4px 0 5px;
  line-height: 1.4375em;
}
.PhoneInputError {
  height: 40px;
  border-bottom: 0.2em solid red;
  font-size: 1rem;
  padding: 4px 0 5px;
  line-height: 1.4375em;
}

.PhoneInputInput {
  outline: none;
  border: 0;
  font-size: 17px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  padding-left: 4px;
}
.PhoneInputInput::placeholder {
  color: #999;
}
.PhoneInputInput:focus .PhoneInput {
  height: 50px;
  border-bottom: 1px solid #3f51b5;

  font-size: 17px;
}
.PhoneInputCountrySelectArrow-color {
  background-color: green;
}
.btnlogin {
  background:  var(--color-button-background) !important;
}