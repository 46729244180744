.quiz_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  //min-height: 500px;
  overflow-y: auto;
  padding: 0.5rem 1rem 3rem 1rem;

  .quiz_wrapper {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    //align-items: flex-start;
    //min-height: 35rem;
    width: 100%;

    &_grow {
      flex-grow: 1;
    }

    &_ques_ans {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      &--question {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
        @media screen and (max-width: 770px) {
          display: grid;
          margin-top: 2rem;
        }
        div:first-child {
          width: 11rem;
          text-align: center;
        }

        & > div {
          padding: 1rem;
          outline: 1px solid var(--color-grey-light-4);
          padding: 0.5rem 1rem;
          border-radius: 5px;
          min-width: 50px;
          font-weight: 800;
        }
        & > p {
          max-width: 600px;
          text-align: justify;
        }
      }

      &--answers {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: left;

        & > div {
          outline: 1px solid var(--color-grey-light-4);
          padding: 0.5rem 1rem;
          border-radius: 5px;
        }
        .selected-answer {
          outline: 2px solid var(--color-primary-light-rgda);
        }
        .ans_green {
          outline: 2px solid green;
        }
        .error {
          outline: 2px solid red;
        }
      }

      &_img-container {
        width: 100%;
        // aspect-ratio: auto 8 / 9;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &--actions {
      display: flex;
      justify-content: space-between;
      margin: 2rem 0rem;
      width: 100%;
      //padding: 0.5rem 2rem;
      .btn {
        outline: var(--color-grey-light-4);
        cursor: pointer;
      }

      .btn-contained {
        border-radius: 3px;
        outline: 1px solid var(--color-primary);
      }
      .btn-contained.primary {
        background-color: var(--color-primary);
        color: #fff;
      }

      .btn-outline {
        border-radius: 3px;
        outline: 1px solid var(--color-primary);
        background: none;
      }
      .btn-outline.primary {
        outline: 1px solid var(--color-primary);
        color: var(--color-primary);
      }

      .left-actions {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      .btn:disabled {
        cursor: not-allowed;
        outline: 1px solid var(--color-grey-light-4);
        color: var(--color-grey-light-4);
      }
    }
  }
  &--footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 6px;
  }
  .btn {
    padding: 0.5rem 1rem;
    border: 0;
  }
  .divider {
    margin: 1rem 0rem;
    // : var(--color-grey-light-4);
  }

  .green {
    background-color: green;
    color: #fff;
  }
  .border {
    outline: 1px solid var(--color-primary-light-rgda);
  }
}
.okButton {
  cursor: pointer;
  border-radius: 3px;
  background-color: var(--color-primary);
  color: #fff;
  padding: 5px;
  border: none;
}

.formative_start_button_container-2 {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  gap: 20px;

  .start_quiz_button {
    font-size: 16px;
    padding: 1rem 5%;
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    border: 0;
    opacity: 1px solid var(--color-primary);
    background-color: var(--color-primary);
    min-width: 100px;
  }
}
.formative_start_button_container {
  width: 100%;
  height: 80%;
  display: grid;
  place-items: center;

  .start_quiz_button {
    font-size: 16px;
    padding: 1rem 5%;
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    border: 0;
    opacity: 1px solid var(--color-primary);
    background-color: var(--color-primary);
  }
}

.start_quiz_button {
  font-size: 16px;
  padding: 1rem 5%;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  border: 0;
  opacity: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}

/* CSS */
.button-3 {
  appearance: none;
  background-color: var(--color-primary);
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: var(--color-primary);
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: var(--color-primary);
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: var(--color-primary);
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}
