.icon {
  font-size: 25px !important;
}

.icon-star {
  fill: #ffda46 !important;
}

.modulesSubHead {
  background: rgb(1, 73, 142);
  background: linear-gradient(
    to right,
    var(--color-primary) 55%,
    var(--color-primary-light) 100%
  );
  padding: 1rem 2rem 1rem 8rem;
  border-radius: 0 30px 30px 0;
  font-size: 1.75rem !important;
  color: var(--color-white);
  max-width: 700px;
}
.modulesObject {
  padding: 1rem 2rem 1rem 0rem;
  font-size: 1.6rem !important;
  color: #777 !important;
  @media screen and (max-width: 500px) {
    width: 27rem;
  }
  @media screen and (max-width: 370px) {
    width: 24rem;
  }
}
.modulesObjecticon {
  padding: 0.8rem 2rem 0.5rem 3rem;
  font-size: 1.6rem !important;
  color: var(--color-grey-dark-2);
  @media screen and (max-width: 500px) {
    padding: 0.5rem;
  }
}
.objflex-between {
  display: flex;
  align-items: center;
}

.loadingcss {
  width: 100% !important;
  min-width: 340px !important;
  @media screen and (max-width: 800px) {
    min-width: 100%!important;
  }
}
.bgimage {
  background-repeat: no-repeat;
  background-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-size: cover;
  width: 35px;
  height: 35px;
  margin-right: 0.5rem;
}
.videoiconDardk {
  background-color: red;
  //   background-image: url('../../assets/media/objects/video.png');
  -webkit-mask-image: url("../../assets/media/objects/videosvg.svg");
  mask-image: url("../../assets/media/objects/videosvg.svg");
}
.audioiconDardk {
  -webkit-mask-image: url("../../assets/media/objects/audiosvg.svg");
  mask-image: url("../../assets/media/objects/audiosvg.svg");
  //background-image: url('../../assets/media/objects/audio.png');
}
.htmliconDardk {
  -webkit-mask-image: url("../../assets/media/objects/htmlsvg.svg");
  mask-image: url("../../assets/media/objects/htmlsvg.svg");
}
.pdficonDardk {
  -webkit-mask-image: url("../../assets/media/objects/pdfsvg.svg");
  mask-image: url("../../assets/media/objects/pdfsvg.svg");
}

.intericonDardk {
  //   background-image: url('../../assets/media/objects/inter.png');
  -webkit-mask-image: url("../../assets/media/objects/swiperblack.svg");
  mask-image: url("../../assets/media/objects/swiperblack.svg");
}
.scormiconDardk {
  //   background-image: url('../../assets/media/objects/scorm.png');
  -webkit-mask-image: url("../../assets/media/objects/scormsvg.svg");
  mask-image: url("../../assets/media/objects/scormsvg.svg");
}
.quiziconDardk {
  //   background-image: url('../../assets/media/objects/quiz.png');
  -webkit-mask-image: url("../../assets/media/objects/quizsvg.svg");
  mask-image: url("../../assets/media/objects/quizsvg.svg");
}
.timer {
  //background-image: url('../../assets/media/timer.png');
  -webkit-mask-image: url("../../assets/media/timer.svg");
  mask-image: url("../../assets/media/timer.svg");
  background-color: gray;
}
.bookmark {
  background-image: url("../../assets/media/bookmark.svg");
}
.modules {
  background-image: url("../../assets/media/modules.png");
}
.swal-wide {
  width: 450px !important;
  height: 200px;
}

.swal-wide button {
  width: 80px !important;
  height: 30px !important;
}
.confirmButton {
  background-color: #13599a !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1.4rem !important;
}
.confirmButton:focus {
  outline: none !important;
  border: none !important;
}
.swal2-deny {
  background-color: transparent !important;
  border: 1px solid #ddd !important;
  color: #000 !important;
  font-size: 1.4rem !important;
  box-shadow: none !important;
}
.classtags {
  padding: 3px 10px 5px 10px;
  border-radius: 4px;
  font-family: MyWebFont;
  border: 1px solid var(--mediumborderclr);
  font-size: 14px;
  margin: 1px 5px 1px 0px;
  height: auto;
  background-color: rgb(242, 242, 242) !important;
  width: 80%;
  min-width: 100px;
  display: inline-block;
}
.confirmClass {
  padding: 0 28px !important;
  text-align: center;
  height: 32px !important;
  background: var(--linear-gradient) !important;
  margin: 0 0.3125em !important;
  border: 0 !important;
  border-radius: 0.25em !important;
  color: #fff !important;
  font-size: 14px !important;
  font-family: MyWebFont1 !important;
  font-weight: bold !important;
}

.cancelClass {
  padding: 0 28px !important;
  text-align: center;
  height: 32px !important;
  background: #fff !important;
  margin: 0 0.3125em !important;
  border: 1px solid !important;
  border-radius: 0.25em !important;
  color: var(--textmediumcolor) !important;
  font-size: 14px !important;
  font-family: MyWebFont1 !important;
  font-weight: bold !important;
}
.coursebtn {
  display: inline-block;
  width: 100% !important;
  border: none !important;
  margin-bottom: 22px !important;
  cursor: pointer !important;
}
.classtags1 {
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
  font-family: MyWebFont;
  border: 1px solid #ddd;
  font-size: 14px;
  margin: 1px 5px 1px 0px;
  height: auto;
  background-color: rgb(242, 242, 242) !important;
  width: 100%;
  min-width: 100px;
  display: inline-block;
  color: #484848;
}
.markcomplete {
  // height: 30px;
  // width: 105px;
  padding: 1.5rem 3rem;
  font-size: 1.3rem !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  cursor: pointer;
  background-color: var(--color-button-background) !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.darkbgcolor {
  background-color: var(--color-button-background) !important;
}
.graybgcolor {
  background-color: #7c7f91 !important;
}
.timerdiv {
  position: absolute;
  right: 30px;
  padding: 4px 8px;
  margin-top: -5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.docmarkcombtn {
  bottom: -5px!important;
  //right: 20px !important;
  top: auto;
  //left: unset !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  //transform: translate(14%, 2%) !important;
}
.MuiRating-root {
  font-size: 3.5rem !important;
  margin-left: 65px;
}
.centeritem {
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: center;
}
.nextcourseswal .swal2-confirm {
  border: none !important;
  background-color: transparent !important;
  color: blue !important;
  font-size: 15px !important;
  line-height: 10px !important;
}
.nextcourseswal h3 {
  font-weight: 400 !important;
}
.greenborder {
  border: 2px solid green;
  border-radius: 5px;
}
.redboreder {
  border: 2px solid red;
  border-radius: 5px;
}
.iframestyle {
  margin-top: 10px;
  position: absolute;
  height: 92%;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  top: 38px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 0;
}

.cancelButton {
  font-size: 1.4rem !important;
}
.normalborder {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.highlightborder {
  border: 1px solid rgb(4, 20, 233);
  border-radius: 5px;
}

.qradiobutton input:checked + label,
.highlightborder {
  background: #f5f8fb !important;
  border: 2px solid #13599a !important;
  border-radius: 5px;
}

.challenge5 {
  width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns:
    repeat(2, 160px)
    380px repeat(2, 160px);
  place-items: center;
}
.challenge2 {
  width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.challenge3 {
  width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}
.challenge4 {
  width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 1000;
}

.responsiveNone {
  @media screen and (max-width: 800px) {
    display: none;
  }
}