.Layoutmain {
  display: flex;
  //grid-template-rows: auto 1fr auto;
  flex-direction: column;
}

.mycontainer {
  min-height: 86vh;
}

.main {
  background-color: transparent;
  padding-bottom: 5rem;
  // margin-top: 4rem;

  //margin-bottom: 4rem;

  // margin: 4rem 0;
  // @media screen and (max-width: 360px) {
  //   margin: 0rem 0;
  // }
  // min-width: 100vh;
  .slide-container {
    // background-color: transparent;
    // padding: 1rem 4rem;
    // margin: 1rem 0rem;

    .slider-container {
      display: flex;
      padding: 0.2rem 0;
      // background-color: transparent;
    }
  }
}

.layout_container {
  padding: 3rem;
  background-color: var(--color-grey-light-5);
  box-shadow: var(--shadow-drak);
}
